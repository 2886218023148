import { BalHeading, BalText } from "@baloise/ds-react";
import { Language } from "@baloise-cfa/form-renderer-frontend";
import {
    MobApiClient,
    OccasionalDriverTitle,
    UsualDriverTitle,
} from "@baloise-cfa/tsclient/mob";
import { formatPrice } from "@lg-cfa/utils";
import { format } from "date-fns";
import { graphql, navigate } from "gatsby";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";

import {
    DocumentCard,
    InsuranceOverviewTable,
    quoteDTO,
    QuoteFormState,
    quoteFormState,
    QuoteLayout,
} from "@modules/quote";
import {
    Footer,
    SummaryCard,
    SummaryCardGridDirection,
    SummaryCardListItem,
} from "@modules/shared/components";
import { PageNames } from "@modules/shared/enums";
import {
    calculateTotalInsurancePrice,
    getAddress,
    getAddressText,
    getNavigationPages,
    toTitle,
} from "@modules/shared/helpers";
import { useSitePaths } from "@modules/shared/hooks";
import { AppState, appState, selectDocuments } from "@modules/shared/state";
import { PageProps } from "@modules/shared/types";

const Overview: React.FC<PageProps> = ({ pageContext }) => {
    const { foundPage: page, language, ownPageObjects: allPages } = pageContext;
    const { t } = useTranslation();
    const innerLanguage: Language =
        language === "nl" ? Language.NL : Language.FR;
    const simulatorData = useRecoilValue<AppState>(appState);
    const quoteData = useRecoilValue<QuoteFormState | null>(quoteFormState);
    const [hasConsent, setHasConsent] = useState(false);
    const documentsData = useRecoilValue(selectDocuments);
    const { nextPage } = getNavigationPages(allPages, page);
    const [isSigningQuote, setIsSigningQuote] = useState(false);
    const { getSitePath } = useSitePaths();

    //pages
    const insurancePage = allPages.find(
        ({ name }) => name === PageNames.QuoteInsurances,
    );
    const vehiclePage = allPages.find(
        ({ name }) => name === PageNames.QuoteVehicle,
    );
    const policyHolderPage = allPages.find(
        ({ name }) => name === PageNames.QuotePolicyHolder,
    );
    const usualDriverPage = allPages.find(
        ({ name }) => name === PageNames.QuoteUsualDriver,
    );

    const carModel = `Ford ${toTitle(
        simulatorData.CarDescription?.carDescription?.model,
    )}`;

    const headDriverIsPolicyHolder =
        quoteData?.drivers?.policyholderInfo?.headDriverIsPolicyHolder;

    const transferDriverData =
        quoteData?.drivers?.usualDriver?.transferDriversData;

    useEffect(() => {
        if (!simulatorData?.InsuranceChoice?.insurance?.type) {
            // Navigate to the product page when there is no insurance choice selected
            // A quote flow can only started when there is a simulation available
            navigate("/");
        }
    }, [simulatorData]);

    const vehicleData: SummaryCardListItem[] = useMemo(() => {
        const model = `Ford ${toTitle(
            simulatorData?.CarDescription?.carDescription?.model,
        )}`;

        const power = `${String(
            simulatorData?.CarDescription?.carDescription?.power,
        )} ${t("all.power.abbr")} (${Math.round(
            Number(simulatorData?.CarDescription?.carDescription?.power) *
                1.362,
        )} ${t("all.power.horsepower")})`;

        return [
            {
                title: "all.carDescription.model",
                description: model,
            },
            {
                title: "quote.vehicle.kmPerYear",
                description: t(
                    `all.options.mileage.${simulatorData?.CarUsage?.mileage}`,
                ) as string,
            },
            {
                title: "all.carDescription.fuelType",
                description: t(
                    `all.fueltype.${simulatorData?.CarDescription?.carDescription?.fuel.toLowerCase()}`,
                ) as string,
            },
            {
                title: "all.carDescription.power",
                description: power,
            },
            {
                title: "quote.vehicle.form.licensePlate",
                description: quoteData?.vehicle?.vehicleInfo?.licensePlate,
            },
        ];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [simulatorData, quoteData]);

    const policyHolderData: SummaryCardListItem[] = useMemo(() => {
        const fullName = headDriverIsPolicyHolder
            ? `${quoteData?.usualDriver?.personalDetails?.firstName} ${quoteData?.usualDriver?.personalDetails?.lastName}`
            : `${quoteData?.policyHolder?.personalDetails?.firstName} ${quoteData?.policyHolder?.personalDetails?.lastName}`;

        const simulatorBirthDay = simulatorData.Driver?.birthday;

        const birthDay = headDriverIsPolicyHolder
            ? quoteData.usualDriver?.personalDetails?.birthDate
            : quoteData?.policyHolder?.personalDetails?.birthDate;

        const phone = headDriverIsPolicyHolder
            ? quoteData.usualDriver?.contactDetails?.telephone
            : quoteData?.policyHolder?.contactDetails?.telephone;

        const email = headDriverIsPolicyHolder
            ? quoteData.usualDriver?.contactDetails?.email
            : quoteData?.policyHolder?.contactDetails?.email;

        const simulatorAddress = getAddress(
            simulatorData.Driver,
            innerLanguage,
        );

        const address = quoteData?.usualDriver?.address?.addressField;

        return [
            {
                title: "quote.policyholder.title",
                description: fullName,
                icon: (
                    <svg
                        width="14"
                        height="17"
                        viewBox="0 0 14 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M9.5 5C9.5 4.125 9 3.3125 8.25 2.84375C7.46875 2.40625 6.5 2.40625 5.75 2.84375C4.96875 3.3125 4.5 4.125 4.5 5C4.5 5.90625 4.96875 6.71875 5.75 7.1875C6.5 7.625 7.46875 7.625 8.25 7.1875C9 6.71875 9.5 5.90625 9.5 5ZM3 5C3 3.59375 3.75 2.28125 5 1.5625C6.21875 0.84375 7.75 0.84375 9 1.5625C10.2188 2.28125 11 3.59375 11 5C11 6.4375 10.2188 7.75 9 8.46875C7.75 9.1875 6.21875 9.1875 5 8.46875C3.75 7.75 3 6.4375 3 5ZM1.53125 15.5H12.4375C12.1562 13.5312 10.4688 12 8.40625 12H5.5625C3.5 12 1.8125 13.5312 1.53125 15.5ZM0 16.0938C0 13 2.46875 10.5 5.5625 10.5H8.40625C11.5 10.5 14 13 14 16.0938C14 16.5938 13.5625 17 13.0625 17H0.90625C0.40625 17 0 16.5938 0 16.0938Z"
                            fill="#102B4E"
                        />
                    </svg>
                ),
            },
            {
                title: "all.birthDay",
                description: transferDriverData
                    ? simulatorBirthDay
                        ? format(new Date(simulatorBirthDay), "dd/MM/YYY")
                        : ""
                    : birthDay
                    ? format(new Date(birthDay), "dd/MM/YYY")
                    : "",
                icon: (
                    <svg
                        width="15"
                        height="16"
                        viewBox="0 0 15 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M5.25 0.75V2H9.75V0.75C9.75 0.34375 10.0625 0 10.5 0C10.9062 0 11.25 0.34375 11.25 0.75V2H12.5C13.5938 2 14.5 2.90625 14.5 4V4.5V6V14C14.5 15.125 13.5938 16 12.5 16H2.5C1.375 16 0.5 15.125 0.5 14V6V4.5V4C0.5 2.90625 1.375 2 2.5 2H3.75V0.75C3.75 0.34375 4.0625 0 4.5 0C4.90625 0 5.25 0.34375 5.25 0.75ZM2 6V14C2 14.2812 2.21875 14.5 2.5 14.5H12.5C12.75 14.5 13 14.2812 13 14V6H2Z"
                            fill="#102B4E"
                        />
                    </svg>
                ),
            },
            {
                title: "all.phone",
                description: phone,
                icon: (
                    <svg
                        width="17"
                        height="18"
                        viewBox="0 0 17 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M11.7188 9.625L15.2188 11.125C15.7812 11.3438 16.0938 11.9375 15.9688 12.5312L15.2188 16.0312C15.0938 16.5938 14.5625 17.0312 14 17.0312C13.7812 17.0312 13.5938 17 13.4062 17C13.0938 17 12.7812 16.9688 12.5 16.9375C5.46875 16.1875 0 10.25 0 3C0 2.4375 0.40625 1.90625 0.96875 1.78125L4.46875 1.03125C5.0625 0.90625 5.65625 1.21875 5.875 1.78125L7.375 5.28125C7.59375 5.78125 7.46875 6.375 7.03125 6.71875L5.75 7.78125C6.59375 9.21875 7.78125 10.4062 9.21875 11.25L10.2812 9.96875C10.625 9.53125 11.2188 9.40625 11.7188 9.625ZM13.7812 15.5L14.4375 12.4062L11.3125 11.0625L10.4062 12.1875C9.9375 12.75 9.125 12.9062 8.46875 12.5312C6.8125 11.5625 5.4375 10.1875 4.46875 8.53125C4.09375 7.875 4.25 7.0625 4.8125 6.59375L5.9375 5.6875L4.59375 2.5625L1.5 3.21875C1.59375 9.96875 7.03125 15.4062 13.7812 15.5Z"
                            fill="#102B4E"
                        />
                    </svg>
                ),
            },
            {
                title: "all.email",
                description: email,
                icon: (
                    <svg
                        width="17"
                        height="12"
                        viewBox="0 0 17 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M2.5 1.5C2.21875 1.5 2 1.75 2 2V2.71875L7.375 7.125C8.03125 7.65625 8.9375 7.65625 9.59375 7.125L15 2.71875V2C15 1.75 14.75 1.5 14.5 1.5H2.5ZM2 4.65625V10C2 10.2812 2.21875 10.5 2.5 10.5H14.5C14.75 10.5 15 10.2812 15 10V4.65625L10.5625 8.28125C9.34375 9.28125 7.625 9.28125 6.4375 8.28125L2 4.65625ZM0.5 2C0.5 0.90625 1.375 0 2.5 0H14.5C15.5938 0 16.5 0.90625 16.5 2V10C16.5 11.125 15.5938 12 14.5 12H2.5C1.375 12 0.5 11.125 0.5 10V2Z"
                            fill="#102B4E"
                        />
                    </svg>
                ),
            },
            {
                title: "all.address",
                description: transferDriverData
                    ? getAddressText(innerLanguage, simulatorAddress)
                    : getAddressText(innerLanguage, address),
                icon: (
                    <svg
                        width="20"
                        height="17"
                        viewBox="0 0 20 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M10.4688 1.1875L18.7188 8.1875C19.0312 8.46875 19.0625 8.9375 18.8125 9.25C18.5312 9.5625 18.0625 9.59375 17.75 9.34375L17 8.6875V14.5C17 15.9062 15.875 17 14.5 17H5.5C4.09375 17 3 15.9062 3 14.5V8.6875L2.21875 9.34375C1.90625 9.59375 1.4375 9.5625 1.15625 9.25C0.90625 8.9375 0.9375 8.46875 1.25 8.1875L9.5 1.1875C9.78125 0.96875 10.1875 0.96875 10.4688 1.1875ZM4.5 14.5C4.5 15.0625 4.9375 15.5 5.5 15.5H7V10.75C7 10.0625 7.53125 9.5 8.25 9.5H11.75C12.4375 9.5 13 10.0625 13 10.75V15.5H14.5C15.0312 15.5 15.5 15.0625 15.5 14.5V7.40625L10 2.75L4.5 7.40625V14.5ZM8.5 15.5H11.5V11H8.5V15.5Z"
                            fill="#102B4E"
                        />
                    </svg>
                ),
            },
        ];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [simulatorData, quoteData]);

    const driversData: SummaryCardListItem[] = useMemo(() => {
        const userDataString = (
            title?: OccasionalDriverTitle | UsualDriverTitle,
            firstName?: string,
            lastName?: string,
            birthDay?: string,
        ) => {
            const titleString =
                title === OccasionalDriverTitle.Mister ? "all.mr" : "all.mrs";

            return `${t(titleString)} ${firstName} ${lastName}, ${
                birthDay ? format(new Date(birthDay), "dd/MM/YYY") : ""
            }`;
        };

        const transferDriverData =
            quoteData?.drivers?.usualDriver?.transferDriversData;

        const usualDriverBirthDay = transferDriverData
            ? simulatorData.Driver?.birthday
            : quoteData?.usualDriver?.personalDetails?.birthDate;

        const usualDriverInfo = userDataString(
            quoteData?.usualDriver?.personalDetails?.title,
            quoteData?.usualDriver?.personalDetails?.firstName,
            quoteData?.usualDriver?.personalDetails?.lastName,
            usualDriverBirthDay,
        );

        const occasionalDriverOne = quoteData?.occasionalDriverOne
            ? userDataString(
                  quoteData.occasionalDriverOne?.personalDetails?.title,
                  quoteData.occasionalDriverOne?.personalDetails?.firstName,
                  quoteData.occasionalDriverOne?.personalDetails?.lastName,
                  quoteData.occasionalDriverOne?.personalDetails?.birthDate,
              )
            : undefined;

        const occasionalDriverTwo = quoteData?.occasionalDriverTwo
            ? userDataString(
                  quoteData.occasionalDriverTwo?.personalDetails?.title,
                  quoteData.occasionalDriverTwo?.personalDetails?.firstName,
                  quoteData.occasionalDriverTwo?.personalDetails?.lastName,
                  quoteData.occasionalDriverTwo?.personalDetails?.birthDate,
              )
            : undefined;

        const simulatorAddress = getAddress(
            simulatorData.Driver,
            innerLanguage,
        );

        const address = quoteData?.usualDriver?.address?.addressField;
        return [
            {
                title: "quote.drivers.form.usualDriver",
                description: [
                    usualDriverInfo,
                    getAddressText(
                        innerLanguage,
                        transferDriverData ? simulatorAddress : address,
                    ),
                ],
                icon: (
                    <svg
                        width="14"
                        height="17"
                        viewBox="0 0 14 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M9.5 5C9.5 4.125 9 3.3125 8.25 2.84375C7.46875 2.40625 6.5 2.40625 5.75 2.84375C4.96875 3.3125 4.5 4.125 4.5 5C4.5 5.90625 4.96875 6.71875 5.75 7.1875C6.5 7.625 7.46875 7.625 8.25 7.1875C9 6.71875 9.5 5.90625 9.5 5ZM3 5C3 3.59375 3.75 2.28125 5 1.5625C6.21875 0.84375 7.75 0.84375 9 1.5625C10.2188 2.28125 11 3.59375 11 5C11 6.4375 10.2188 7.75 9 8.46875C7.75 9.1875 6.21875 9.1875 5 8.46875C3.75 7.75 3 6.4375 3 5ZM1.53125 15.5H12.4375C12.1562 13.5312 10.4688 12 8.40625 12H5.5625C3.5 12 1.8125 13.5312 1.53125 15.5ZM0 16.0938C0 13 2.46875 10.5 5.5625 10.5H8.40625C11.5 10.5 14 13 14 16.0938C14 16.5938 13.5625 17 13.0625 17H0.90625C0.40625 17 0 16.5938 0 16.0938Z"
                            fill="#102B4E"
                        />
                    </svg>
                ),
            },
            {
                title: "quote.drivers.form.occasionalDrivers",
                description:
                    occasionalDriverOne && occasionalDriverTwo
                        ? [occasionalDriverOne, occasionalDriverTwo]
                        : undefined,
                icon: (
                    <svg
                        width="20"
                        height="17"
                        viewBox="0 0 20 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M4.5 5C4.5 5.90625 4.96875 6.71875 5.75 7.1875C6.5 7.625 7.46875 7.625 8.25 7.1875C9 6.71875 9.5 5.90625 9.5 5C9.5 4.125 9 3.3125 8.25 2.84375C7.46875 2.40625 6.5 2.40625 5.75 2.84375C4.96875 3.3125 4.5 4.125 4.5 5ZM11 5C11 6.4375 10.2188 7.75 9 8.46875C7.75 9.1875 6.21875 9.1875 5 8.46875C3.75 7.75 3 6.4375 3 5C3 3.59375 3.75 2.28125 5 1.5625C6.21875 0.84375 7.75 0.84375 9 1.5625C10.2188 2.28125 11 3.59375 11 5ZM8.40625 12H5.5625C3.5 12 1.8125 13.5312 1.53125 15.5H12.4375C12.1562 13.5312 10.4688 12 8.40625 12ZM5.5625 10.5H7H8.40625C11.5 10.5 14 13 14 16.0938C14 16.5938 13.5625 17 13.0625 17H0.90625C0.40625 17 0 16.5938 0 16.0938C0 13 2.46875 10.5 5.5625 10.5ZM15.75 10.75V8.75H13.75C13.3125 8.75 13 8.4375 13 8C13 7.59375 13.3125 7.25 13.75 7.25H15.75V5.25C15.75 4.84375 16.0625 4.5 16.5 4.5C16.9062 4.5 17.25 4.84375 17.25 5.25V7.25H19.25C19.6562 7.25 20 7.59375 20 8C20 8.4375 19.6562 8.75 19.25 8.75H17.25V10.75C17.25 11.1875 16.9062 11.5 16.5 11.5C16.0625 11.5 15.75 11.1875 15.75 10.75Z"
                            fill="#102B4E"
                        />
                    </svg>
                ),
            },
        ];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [simulatorData, quoteData]);

    const generalConditionsDocument = useMemo(
        () =>
            documentsData.documents.find((document) =>
                [
                    "Algemene voorwaarden - Mobility Safe",
                    "Conditions générales - Mobility Safe",
                ].includes(document.itemDisplayName),
            ),
        [documentsData],
    );

    const quoteDocuments = useMemo(
        () =>
            (documentsData.documents ?? []).filter((document) =>
                [
                    "Kennismakingsbrochure",
                    "Brochure de Presentation",
                    "IPID - Mobility Safe",
                ].includes(document.itemDisplayName),
            ),
        [documentsData],
    );

    const handleSign = async () => {
        setIsSigningQuote(true);
        const errorPage = getSitePath("500");
        const quoteRequestData = quoteDTO(
            language,
            simulatorData,
            quoteData ?? {},
        );
        const client = new MobApiClient(
            `${process.env.GATSBY_API_URL}/api/mob`,
        );

        try {
            await client.fordPostQuote(
                process.env.GATSBY_SALES_PARTNER_CUSTOMER_ZONE_APPLICATION_ID ??
                    "",
                quoteRequestData,
            );
            setIsSigningQuote(false);
            navigate(nextPage?.paths[language] ?? "");
        } catch (error) {
            setIsSigningQuote(false);
            navigate(errorPage);
        }
    };

    const handleHasConsentChanged = (hasConsent: boolean) => {
        setHasConsent(hasConsent);
    };

    return (
        <QuoteLayout
            title={t("quote.overview.title")}
            page={page}
            allPages={allPages}
            language={language}
            hideHelp={false}
        >
            <main className="container is-compact mt-large">
                <div className="flex flex-direction-column gap-xx-large">
                    <div>
                        <BalHeading level="h1">
                            {t("quote.overview.title")}
                        </BalHeading>
                        <BalText className="mb-large">
                            {t("quote.overview.intro")}
                        </BalText>
                        <DocumentCard
                            documents={quoteDocuments}
                            consentUrl={generalConditionsDocument?.itemUrlLink}
                            price={formatPrice.format(
                                calculateTotalInsurancePrice(
                                    quoteData?.insurances?.insurance,
                                ),
                            )}
                            car={carModel}
                            language={innerLanguage}
                            onSign={handleSign}
                            loading={isSigningQuote}
                            hasConsent={hasConsent}
                            hasConsentChanged={handleHasConsentChanged}
                        />
                    </div>
                    <BalHeading subtitle level="h2">
                        {t("quote.overview.data.title")}
                    </BalHeading>
                    <SummaryCard
                        label={t("quote.overview.data.insurance.title")}
                        url={insurancePage?.paths[language]}
                    >
                        <InsuranceOverviewTable
                            insuranceData={quoteData?.insurances}
                        />
                    </SummaryCard>
                    <SummaryCard
                        label={t("quote.navigation.vehicle")}
                        url={vehiclePage?.paths[language]}
                        list={vehicleData}
                    />
                    <SummaryCard
                        label={t("quote.policyholder.title")}
                        url={
                            quoteData?.drivers?.policyholderInfo
                                ?.headDriverIsPolicyHolder
                                ? usualDriverPage?.paths[language]
                                : policyHolderPage?.paths[language]
                        }
                        list={policyHolderData}
                    />
                    <SummaryCard
                        label={t("quote.overview.data.drivers.title")}
                        url={usualDriverPage?.paths[language]}
                        list={driversData}
                        gridDirection={SummaryCardGridDirection.Col}
                    />
                    <div>
                        <BalHeading className="mb-medium" subtitle level="h2">
                            {t("quote.overview.closeQuote")}
                        </BalHeading>
                        <DocumentCard
                            consentUrl={generalConditionsDocument?.itemUrlLink}
                            documents={quoteDocuments}
                            price={formatPrice.format(
                                calculateTotalInsurancePrice(
                                    quoteData?.insurances?.insurance,
                                ),
                            )}
                            car={carModel}
                            language={innerLanguage}
                            onSign={handleSign}
                            loading={isSigningQuote}
                            hasConsent={hasConsent}
                            hasConsentChanged={handleHasConsentChanged}
                        />
                    </div>
                </div>
                <Footer />
            </main>
        </QuoteLayout>
    );
};

export default Overview;

export const pageQuery = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
